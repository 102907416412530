var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"main-header",attrs:{"id":"header"}},[(!(_vm.currentUrl == 'open-bank-account-variant1' ||
                            _vm.currentUrl == 'open-bank-account-v1'||
                            _vm.currentUrl == 'open-bank-account-variant2'||
                            _vm.currentUrl == 'open-bank-account-v2'))?_c('div',{staticClass:"top-header"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"main-menu"},[_c('div',{staticClass:"container-fluid"},[_c('b-navbar',{staticClass:"bg-light p-0",attrs:{"toggleable":"lg"}},[(!(_vm.currentUrl == 'open-bank-account-variant1' ||
                            _vm.currentUrl == 'open-bank-account-v1'||
                            _vm.currentUrl == 'open-bank-account-variant2'||
                            _vm.currentUrl == 'open-bank-account-v2'))?_c('b-navbar-brand',{staticClass:"header-name",attrs:{"to":_vm.currentLayout == 'customer-layout' ? '/customer-home' : '/home'}},[_c('img',{attrs:{"src":_vm.navbar_details.brand_logo,"width":"223","alt":"Payangel Business"}})]):_vm._e(),_c('b-navbar-toggle',{attrs:{"target":"navbarColor01"}}),_c('b-collapse',{attrs:{"id":"navbarColor01","is-nav":""}},[_c('b-nav',{staticClass:"navbar-nav ml-auto"},[((!(_vm.currentLayout == 'auth-v2-layout' && 
                            (_vm.currentUrl == 'open-bank-account-variant1' ||
                            _vm.currentUrl == 'open-bank-account-v1'||
                            _vm.currentUrl == 'open-bank-account-variant2'||
                            _vm.currentUrl == 'open-bank-account-v2')) &&
                            _vm.currentLayout != 'customer-layout'))?_c('b-nav-form',[_c('router-link',{staticClass:"nav-link btn btn-login",attrs:{"to":"/authentication/page-login"}},[_vm._v("Log in")])],1):_vm._e(),(_vm.currentLayout == 'customer-layout' && _vm.currentUrl != 'customer')?_c('b-nav-form',[_c('router-link',{staticClass:"nav-link btn btn-outline",attrs:{"to":"/customer/login"}},[_vm._v("Log in to Personal")]),_c('b-button',{staticClass:"nav-link btn btn-login",on:{"click":_vm.openSignInModal}},[_vm._v("Login/Register")])],1):_vm._e()],1)],1)],1)],1)]),_c('auth-login-register-modal'),_c('mobile-number-varify-modal'),_c('user-details-modal'),_c('set-password-modal'),_c('email-varification-modal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ul',{staticClass:"list-unstyled d-flex justify-content-end"},[_c('li',[_c('a',{attrs:{"href":"tel:+44 2037-476-765"}},[_c('img',{attrs:{"src":require("@/assets/home_image/outgoing-call.svg"),"width":"18","alt":""}}),_c('span',[_vm._v("+44 2037-476-765")])])]),_c('li',[_c('a',{attrs:{"href":"https://wa.me/+447908061086","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/home_image/outgoing-call.svg"),"width":"18","alt":""}}),_c('img',{attrs:{"src":require("@/assets/home_image/whatsapp-call.svg"),"width":"18","alt":""}}),_c('span',[_vm._v("+44 790-806-1086")])])])])])])])
}]

export { render, staticRenderFns }